/* eslint-disable no-irregular-whitespace */
<template>
	<div class="app-container">
		<div class="wrapTop" style>
			<el-row :gutter="20" style="width:100%;">
				<el-col :xs="24" :sm="24" :md="24" :xl="18" style="min-height:560px;">
					<el-card class="box-card" shadow="hover">
						<!-- <div slot="header" class="clearfix comTitle"><span>基本信息</span></div> -->
						<div class="baseInfo">
							<!-- <img :src="devBaseForm.devInfoImgUrlStr" alt srcset style="width:120px; height:120px;" /> -->
							<div style="text-align: center">
								<el-image v-if="devBaseForm.devInfoImgUrlStr" class="p5b1"
									style="width: 100px; height: 100px" :src="devBaseForm.devInfoImgUrlStr"
									:preview-src-list="[devBaseForm.devInfoImgUrlStr]" :fit="'contain'">
									<div slot="error" class="image-slot">暂无图片</div>
								</el-image>
								<br />
								<el-button style="margin-top: 10px" @click="deviceFlowHandler">
									设备历程
								</el-button>
								<br />
								<el-button style="margin-top: 10px" @click="deviceExtHandler"
									:disabled="!devBaseForm.devExtImgUrls">
									相关证照
								</el-button>
								<br />
								<template v-if="devBaseForm.stoped">
									<el-button type="success" style="margin-top: 10px" @click="onCanceltoggleStopUsing">
										取消报废
									</el-button>
								</template>
								<template v-else>
									<el-button type="danger" id="btn-update-row" v-authorize="{
										name: 'update',
										type: 'devBase',
										id: 'btn-update-row'
									}" style="margin-top: 10px" @click="toggleStopUsingShow">
										设备报废
									</el-button>
								</template>
							</div>
							<div class="tabInfo">
								<el-tabs v-model="activeBaseInfoName" @tab-click="handleClickBaseInfoTab"
									style="width:100%;">
									<el-tab-pane label="基本属性" name="first">
										<ul>
											<li>
												<b>设备类别</b>
												<span>{{ devBaseForm.devInfoTypeName }}</span>
											</li>
											<li>
												<b>标准型号</b>
												<span>{{ devBaseForm.devInfoModel }}</span>
											</li>
											<li>
												<b>设备编码</b>
												<span>{{ devBaseForm.devInfoNumber }}</span>
											</li>
											<li>
												<b>设备名称</b>
												<span>{{ devBaseForm.devInfoName }}</span>
											</li>
											<li>
												<b>规格型号</b>
												<span>{{ devBaseForm.devInfoNns1 }}</span>
											</li>
											<li>
												<b>计量单位</b>
												<span>{{ devBaseForm.devInfoNns2Name }}</span>
											</li>
											<li>
												<b>生产厂家</b>
												<span>{{ devBaseForm.devInfoFactoryName }}</span>
											</li>
											<li>
												<b>供应商</b>
												<span>{{ devBaseForm.supplierName }}</span>
											</li>
											<li>
												<b>设备品牌</b>
												<span>{{ devBaseForm.devInfoBrand }}</span>
											</li>
											<li>
												<b>生产序列号</b>
												<span>{{ devBaseForm.devInfoSerialNo }}</span>
											</li>
											<li>
												<b>购入日期</b>
												<span>{{ devBaseForm.devInfoEnableTime }}</span>
											</li>
											<li>
												<b>设备金额</b>
												<span>{{ devBaseForm.devInfoNns3 }}</span>
											</li>
											<li>
												<b>残值</b>
												<span>{{ devBaseForm.residual }}</span>
											</li>
											<li>
												<b>现值</b>
												<span>{{ devBaseForm.nowprice }}</span>
											</li>
											<li>
												<b>使用科室</b>
												<span>
													{{ devBaseForm.devInfoDepartmentName }}
													<span style="color:#67C23A;" v-if="devBaseForm.useType === 1">
														(在账在用)
													</span>
													<span style="color:#F56C6C;" v-else-if="devBaseForm.useType === 2">
														(不在账在用)
													</span>
													<span style="color:#E6A23C;" v-else-if="devBaseForm.useType === 3">
														(在账不在用)
													</span>
													<span v-else></span>
												</span>
											</li>
											<li>
												<b>68分类-大类</b>
												<span>{{ devBaseForm.type68BigName }}</span>
											</li>
											<li>
												<b>68分类-小类</b>
												<span>{{ devBaseForm.type68SmallName }}</span>
											</li>
											<li>
												<b>使用房间</b>
												<span>{{ devBaseForm.devPosition }}</span>
											</li>
											<li>
												<b>保管人</b>
												<span>{{ devBaseForm.keeper }}</span>
											</li>
											<li v-if="devBaseForm.stoped">
												<b>报废时间</b>
												<span>
													{{
														devBaseForm.stopDate
														| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
													}}
												</span>
											</li>
											<li v-if="devBaseForm.stoped" style="width: 100%; height: auto">
												<b>报废原因</b>
												<span>{{ devBaseForm.stopReason }}</span>
											</li>
											<li style="width: 100%; height: auto">
												<b>备注</b>
												<span>
													<pre style="white-space: pre-wrap; overflow-wrap: anywhere;">{{ devBaseForm.devInfoRemarks }}
						</pre>
												</span>
											</li>
										</ul>
									</el-tab-pane>
									<el-tab-pane label="扩展字段" name="second">
										<ul>
											<li>
												<b>医院资产编码</b>
												<span>{{ devBaseForm.hospitalAssetCode }}</span>
											</li>
											<li>
												<b>厂家维保到期时间</b>
												<span>
													{{
														devBaseForm.maintainDate
														| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
													}}
												</span>
											</li>
											<li>
												<b>在账状态</b>
												<span style="color:#67C23A;" v-if="devBaseForm.useType === 1">
													在账在用
												</span>
												<span style="color:#F56C6C;" v-else-if="devBaseForm.useType === 2">
													不在账在用
												</span>
												<span style="color:#E6A23C;" v-else-if="devBaseForm.useType === 3">
													在账不在用
												</span>
												<span v-else></span>
											</li>
											<li>
												<b>使用年限</b>
												<span>{{ devBaseForm.useyear }}</span>
											</li>
											<li>
												<b>残值</b>
												<span>{{ devBaseForm.residual }}</span>
											</li>
											<li>
												<b>到货时间</b>
												<span>
													{{
														devBaseForm.reciveGoodsDate
														| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
													}}
												</span>
											</li>
											<li>
												<b>拆箱时间</b>
												<span>
													{{
														devBaseForm.openboxDate
														| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
													}}
												</span>
											</li>
											<li>
												<b>安装调试时间</b>
												<span>
													{{
														devBaseForm.installDebugDate
														| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
													}}
												</span>
											</li>
											<li>
												<b>验收时间</b>
												<span>
													{{
														devBaseForm.checkInstallDate
														| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
													}}
												</span>
											</li>
											<li>
												<b>入库时间</b>
												<span>
													{{
														devBaseForm.inWarehouseDate
														| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
													}}
												</span>
											</li>
											<li>
												<b>出库时间</b>
												<span>
													{{
														devBaseForm.outWarehouseDate
														| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
													}}
												</span>
											</li>
											<li>
												<b>是否在合同范围内</b>
												<span>
													{{ devBaseForm.inContract == null ? '' : devBaseForm.inContract ?
													'是' : '否'}}
												</span>
											</li>
											<li style="width: 100%; height: auto">
												<b>包含配件</b>
												<span>
													<pre
														style="white-space: pre-wrap; overflow-wrap: anywhere;">{{ devBaseForm.partsList }}</pre>
												</span>
											</li>
										</ul>
									</el-tab-pane>
									<el-tab-pane label="属性信息" name="third">
										<ul>
											<li>
												<b>是否特种设备</b>
												<span>
													{{
														devBaseForm.isSpecialTypeDev == 1
															? '是'
															: devBaseForm.isSpecialTypeDev == 0
																? '否'
																: ''
													}}
												</span>
											</li>
											<li>
												<b>是否计量设备</b>
												<span>
													{{
														devBaseForm.isMeteringDev == 1
															? '是'
															: devBaseForm.isSpecialTypeDev == 0
																? '否'
																: ''
													}}
												</span>
											</li>
											<li>
												<b>巡检周期</b>
												<span>{{ devBaseForm.inspectPeriod }}(月)</span>
											</li>
											<li>
												<b>下次巡检时间</b>
												<span>{{ devBaseForm.nextInspectTime }}</span>
											</li>
											<li>
												<b>巡检提前通知</b>
												<span>{{ devBaseForm.inspectRemindDays }}(天)</span>
											</li>
											<li></li>
											<li>
												<b>保养周期</b>
												<span>{{ devBaseForm.upkeepPeriod }}(月)</span>
											</li>
											<li>
												<b>下次保养时间</b>
												<span>{{ devBaseForm.nextUpkeepTime }}</span>
											</li>
											<li>
												<b>保养提前通知</b>
												<span>{{ devBaseForm.upkeepRemindDays }}(天)</span>
											</li>
											<li></li>
											<li v-if="devBaseForm.isMeteringDev === 1">
												<b>计量周期</b>
												<span>{{ devBaseForm.testCycle }}(月)</span>
											</li>
											<li v-if="devBaseForm.isMeteringDev === 1">
												<b>下次待检日期</b>
												<span>{{ devBaseForm.nextTestTime }}</span>
											</li>
											<li v-if="devBaseForm.isMeteringDev === 1">
												<b>提前提醒天数</b>
												<span>{{ devBaseForm.checkCountDown }}(天)</span>
											</li>
										</ul>
									</el-tab-pane>
									<el-tab-pane label="维保信息" name="fourth">
										<ul>
											<li>
												<b>维修公司</b>
												<!-- <span>{{ devBaseForm.serviceOrganizationId }}</span> -->
												<span>{{ devBaseForm.orgName }}</span>
											</li>
											<li>
												<b>报修期限</b>
												<span>{{ devBaseForm.transferBatchNumber }}</span>
											</li>
											<li>
												<b>维修开始时间</b>
												<span>
													{{
														devBaseForm.signBeginTime
														| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
													}}
												</span>
											</li>
											<li>
												<b>维修结束时间</b>
												<span>
													{{
														devBaseForm.signEndTime
														| parseTime('{y}-{m}-{d} {h}:{i}:{s}')
													}}
												</span>
											</li>
											<li style="width: 100%; height: auto">
												<b>维保说明</b>
												<span>
													<pre
														style="white-space: pre-wrap; overflow-wrap: anywhere;">{{ devBaseForm.remarks }}</pre>
												</span>
											</li>
										</ul>
									</el-tab-pane>
									<el-tab-pane label="相关审批" name="five">
										<div class="dialogApplySearch">
											<el-form class="form" ref="queryInfoDevApply" :model="queryInfoDevApply"
												label-width="60px" @submit.native.prevent
												@keyup.enter.native="getDevApplyList">
												<el-form-item label="标题">
													<el-input style="width:110px;" v-model="queryInfoDevApply.title"
														placeholder="请输入标题" />
												</el-form-item>
												<el-form-item label="申请人">
													<el-input style="width:110px;"
														v-model="queryInfoDevApply.applicantName"
														placeholder="请输入申请人" />
												</el-form-item>
												<el-form-item label="审批人">
													<el-input style="width:110px;"
														v-model="queryInfoDevApply.approverName" placeholder="请输审批人" />
												</el-form-item>
												<el-form-item label="日期">
													<el-date-picker style="width:220px;" v-model="dataArrDevApply"
														type="datetimerange" range-separator="至"
														start-placeholder="开始日期" end-placeholder="结束日期"
														value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
												</el-form-item>
												<el-form-item label="" label-width="0" style="margin-left:10px;">
													<el-button class="filter-item" size="mini" type="success"
														icon="el-icon-search" @click="getDevApplyList">
														搜索
													</el-button>

													<el-button class="filter-item" size="mini"
														icon="el-icon-refresh-left" @click="resetForm1('searchForm')">
														重置
													</el-button>
												</el-form-item>
											</el-form>
										</div>
										<el-button type="primary" @click="showApplyDialog">
											编辑相关审批
										</el-button>
										<div style="width:100%; height:10px;"></div>

										<el-table ref="singleTable" :data="tableDataDevApply" highlight-current-row
											style="width: 100%" :header-cell-style="{ background: '#F4F4F4' }">
											<el-table-column property="title" label="标题"></el-table-column>
											<el-table-column property="typeName" label="分类"></el-table-column>
											<el-table-column property="body" label="内容"></el-table-column>
											<el-table-column property="applicantName" label="申请人"></el-table-column>
											<el-table-column property="approverName" label="审批人"></el-table-column>
											<el-table-column property="applyDate" label="时间">
												<template slot-scope="scope">
													{{
														scope.row.applyDate
														| parseTime('{y}-{m}-{d}')
													}}
												</template>
											</el-table-column>
											<el-table-column label="操作" align="left" fixed="right" width="200">
												<template slot-scope="scope">
													<el-button id="btn-remove-row" type="danger"
														@click="delDevApplyDev([scope.row.id])">
														删除
													</el-button>
													<el-button id="btn-download-row" type="success"
														@click="downloadFile(scope.row)"
														:disabled="!scope.row.filepath">
														下载文件
													</el-button>
												</template>
											</el-table-column>
										</el-table>

										<el-pagination style="margin-top: 20px; margin-bottom: 20px"
											@size-change="handleDevApplySizeChange"
											@current-change="handleDevApplyCurrentChange"
											:current-page="queryInfoDevApply.pageNum" :page-sizes="[10, 20, 30, 50]"
											:page-size="queryInfoDevApply.pageSize"
											layout="total, sizes, prev, pager, next, jumper"
											:total="totalDevApply"></el-pagination>
									</el-tab-pane>

									<el-tab-pane label="相关文档" name="six">
										<div class="dialogDocSearch">
											<el-form class="form" ref="queryInfoDevDoc" :model="queryInfoDevDoc"
												label-width="60px" @submit.native.prevent
												@keyup.enter.native="getDevDocList">
												<el-form-item label="标题">
													<el-input v-model="queryInfoDevDoc.title" placeholder="请输入标题" />
												</el-form-item>
												<el-form-item label="" label-width="0" style="margin-left:10px;">
													<el-button class="filter-item" size="mini" type="success"
														icon="el-icon-search" @click="getDevDocList">
														搜索
													</el-button>

													<el-button class="filter-item" size="mini"
														icon="el-icon-refresh-left"
														@click="resetFormDevDoc('searchForm')">
														重置
													</el-button>
												</el-form-item>
											</el-form>
										</div>
										<el-button type="primary" @click="showDocDialog">
											编辑相关文档
										</el-button>
										<div style="width:100%; height:10px;"></div>

										<el-table ref="singleTable" :data="tableDataDevDoc" highlight-current-row
											style="width: 100%" :header-cell-style="{ background: '#F4F4F4' }">
											<el-table-column property="title" label="标题"></el-table-column>
											<el-table-column property="creatorName" label="上传人"></el-table-column>
											<el-table-column property="createTime" label="上传时间">
												<template slot-scope="scope">
													{{
														scope.row.createTime
														| parseTime('{y}-{m}-{d}')
													}}
												</template>
											</el-table-column>
											<el-table-column label="操作" align="left" fixed="right" width="200">
												<template slot-scope="scope">
													<el-button id="btn-remove-row" type="danger"
														@click="delDevDocDev([scope.row.id])">
														删除
													</el-button>
													<el-button id="btn-download-row" type="success"
														@click="downloadFileDevDoc(scope.row)"
														:disabled="!scope.row.files">
														下载文件
													</el-button>
												</template>
											</el-table-column>
										</el-table>

										<el-pagination style="margin-top: 20px; margin-bottom: 20px"
											@size-change="handleDevDocSizeChange"
											@current-change="handleDevDocCurrentChange"
											:current-page="queryInfoDevDoc.pageNum" :page-sizes="[10, 20, 30, 50]"
											:page-size="queryInfoDevDoc.pageSize"
											layout="total, sizes, prev, pager, next, jumper"
											:total="totalDevDoc"></el-pagination>
									</el-tab-pane>

									<el-tab-pane label="相关操作" name="seven">
										<div class="dialogOprateSearch">
											<el-form class="form" ref="queryInfoDevOprate" :model="queryInfoDevOprate"
												label-width="60px" @submit.native.prevent
												@keyup.enter.native="getDevOprateList">
												<el-form-item label="操作人">
													<el-input style="width:110px;" v-model="queryInfoDevOprate.opname"
														placeholder="请输入操作人" />
												</el-form-item>

												<el-form-item label="日期">
													<el-date-picker style="width:220px;" v-model="dataArrDevOprate"
														type="datetimerange" range-separator="至"
														start-placeholder="开始日期" end-placeholder="结束日期"
														value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
												</el-form-item>
												<el-form-item label="" label-width="0" style="margin-left:10px;">
													<el-button class="filter-item" size="mini" type="success"
														icon="el-icon-search" @click="getDevOprateList">
														搜索
													</el-button>

													<el-button class="filter-item" size="mini"
														icon="el-icon-refresh-left"
														@click="resetFormDevOprate('searchForm')">
														重置
													</el-button>
												</el-form-item>
											</el-form>
										</div>
										<el-button type="primary" @click="showOprateDialog">
											新增
										</el-button>
										<div style="width:100%; height:10px;"></div>

										<el-table ref="singleTable" :data="tableDataDevOprate" highlight-current-row
											style="width: 100%" :header-cell-style="{ background: '#F4F4F4' }">
											<el-table-column property="body" label="操作内容"></el-table-column>
											<el-table-column property="opname" label="操作人"></el-table-column>
											<el-table-column property="opresult" label="操作结果"></el-table-column>
											<el-table-column property="opdate" label="时间">
												<template slot-scope="scope">
													{{
														scope.row.opdate | parseTime('{y}-{m}-{d}')
													}}
												</template>
											</el-table-column>
											<el-table-column label="操作" align="left" fixed="right" width="200">
												<template slot-scope="scope">
													<el-button id="btn-remove-row" type="success"
														@click="edidSingleHandlerOprate(scope.row)">
														编辑
													</el-button>
													<el-button id="btn-remove-row" type="danger" @click="
														deleteSingleHandlerOprate([
															scope.row.id
														])
														">
														删除
													</el-button>
												</template>
											</el-table-column>
										</el-table>

										<el-pagination style="margin-top: 20px; margin-bottom: 20px"
											@size-change="handleDevOprateSizeChange"
											@current-change="handleDevOprateCurrentChange"
											:current-page="queryInfoDevOprate.pageNum" :page-sizes="[10, 20, 30, 50]"
											:page-size="queryInfoDevOprate.pageSize"
											layout="total, sizes, prev, pager, next, jumper"
											:total="totalDevOprate"></el-pagination>
									</el-tab-pane>
								</el-tabs>
							</div>
						</div>
					</el-card>
				</el-col>
				<el-col :xs="12" :sm="12" :md="12" :xl="3" style="">
					<el-card class="box-card box-card-QR" style="height: 100%" shadow="hover">
						<div slot="header" class="clearfix comTitle" style="display: flex">
							<span style="font-size:14px;">设备二维码</span>
							<el-button :type="Number(devBaseForm.isRepair) === 0 ? 'warning' : 'success'" plain
								size="mini">
								{{ Number(devBaseForm.isRepair) === 0 ? '正在维护' : '正常工作' }}
							</el-button>
						</div>

						<div class="QRCode"
							style="display: flex; flex-wrap:wrap; flex-direction:column;justify-content:center;">
							<!-- <h3>设备二维码</h3> -->
							<div style="height: 15px"></div>
							<div id="printMe" style=" padding: 15px 0px">
								<div id="qrcode" ref="qrcode" style="display:flex; justify-content:center;"></div>
							</div>

							<!-- <p class="pringQR" v-print="'#printMe'">
								<el-button type="primary" icon="el-icon-printer">打印设备二维码</el-button>
																											</p>-->

							<ul style="display:none;">
								<li style="clear: both;float: left;  font-size: 15px">
									{{ devBaseForm.devInfoName }}
								</li>
								<li style="clear: both;float: left;  font-size: 15px">
									{{ devBaseForm.devInfoDepartmentName }}
								</li>
								<li style="clear: both;float: left;  font-size: 15px">
									{{ devBaseForm.devInfoNns1 }}
								</li>
								<li style="clear: both;float: left;  font-size: 15px">
									YIGONG CLOUD
								</li>
							</ul>
							<p class="pringQR">
								<el-button type="default" @click="showPrintDialog" icon="el-icon-printer">
									打印设备二维码
								</el-button>
							</p>
						</div>
					</el-card>
				</el-col>
				<el-col :xs="12" :sm="12" :md="12" :xl="3" style="">
					<el-card class="box-card" style="height: 100%" shadow="hover">
						<div slot="header" class="clearfix comTitle" style="text-align:center;">
							<span style="font-size:14px;">维修公司基本信息</span>
						</div>

						<div class="companyInfo">
							<el-image v-if="devBaseForm.org.logoStr" class="devBaseFormLogoStr p5b1"
								style="width: 105px; height: 105px" :src="devBaseForm.org.logoStr"
								:preview-src-list="[devBaseForm.org.logoStr]" :fit="'contain'">
								<div slot="error" class="image-slot">暂无图片</div>
							</el-image>

							<div class="colorafaeb3 f16">{{ devBaseForm.org.name }}</div>
							<div class="colorafaeb3">{{ devBaseForm.org.phone }}</div>
						</div>
					</el-card>
				</el-col>
			</el-row>
		</div>
		<div class="tab" style="margin-top: 30px">
			<el-tabs v-model="activeName" @tab-click="handleClickTab">
				<el-tab-pane label="报修记录" name="1">
					<el-table ref="singleTable" :data="tableDataRepair" highlight-current-row style="width: 100%"
						:header-cell-style="{ background: '#F4F4F4' }">
						<el-table-column type="index" width="50"></el-table-column>
						<el-table-column property="code" label="报修单" width="120"></el-table-column>
						<el-table-column property="repairUserName" label="报修人"></el-table-column>
						<el-table-column property="repairUserPhone" label="报修人电话"></el-table-column>
						<el-table-column property="serviceName" label="服务机构名称"></el-table-column>
						<el-table-column property="actionCompleteName" label="完成工程师"></el-table-column>
						<el-table-column property="engineerName" label="服务工程师"></el-table-column>
						<el-table-column property="engineerPhone" label="工程师电话"></el-table-column>
						<el-table-column property="actionState" label="维修状态">
							<template slot-scope="scope">
								<span class="equip_state" v-if="Number(scope.row.actionState) === 0">
									待接单
								</span>
								<span class="equip_state equip_state1" v-else-if="Number(scope.row.actionState) === 1">
									已响应
								</span>
								<span class="equip_state equip_state2" v-else-if="Number(scope.row.actionState) === 2">
									已到场
								</span>
								<span class="equip_state equip_state3" v-else-if="Number(scope.row.actionState) === 3">
									维修中
								</span>
								<span class="equip_state equip_state4" v-else-if="Number(scope.row.actionState) === 4">
									待评价
								</span>
								<span class="equip_state equip_state5" v-else-if="Number(scope.row.actionState) === 5">
									已完成
								</span>
								<span v-else></span>
							</template>
						</el-table-column>
						<el-table-column property="urgentLv" label="紧急等级">
							<template slot-scope="scope">
								<el-link style="cursor: initial" type="danger" :underline="false"
									v-if="Number(scope.row.urgentLv) === 0">
									非常紧急
								</el-link>
								<el-link style="cursor: initial" type="warning" :underline="false"
									v-else-if="Number(scope.row.urgentLv) === 1">
									比较紧急
								</el-link>
								<el-link style="cursor: initial" type="primary" :underline="false" v-else>
									一般紧急
								</el-link>
							</template>
						</el-table-column>
						<el-table-column property="faultDescription" label="故障描述"></el-table-column>
						<!-- <el-table-column property="faultType" label="故障类型"></el-table-column> -->
						<el-table-column property="faultTypeName" label="故障名称"></el-table-column>
						<el-table-column property="repairEvaluationLv" label="报修评价星级">
							<template slot-scope="scope">
								<i class="el-icon-star-on yellow f14"
									v-for="(item, index) in Number(scope.row.repairEvaluationLv)" :key="index"></i>
							</template>
						</el-table-column>
						<el-table-column property="repairTime" label="报修时间" width="150">
							<!-- <template slot-scope="scope">{{ scope.row.repairTime | parseTime('{y}-{m}-{d}') }}</template> -->
						</el-table-column>
						<el-table-column property="actionCompleteTime" label="报修完成时间" width="150">
							<!-- <template slot-scope="scope">{{ scope.row.actionCompleteTime | parseTime('{y}-{m}-{d}') }}</template> -->
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="巡检记录" name="2">
					<el-table ref="singleTable" :data="tableDataMaintain" highlight-current-row style="width: 100%"
						:header-cell-style="{ background: '#F4F4F4' }">
						<el-table-column type="index" width="50"></el-table-column>
						<el-table-column property="listNum" label="单号"></el-table-column>
						<el-table-column property="devNumber" label="设备数量"></el-table-column>
						<el-table-column property="dutyPeopleName" label="计划负责人"></el-table-column>
						<el-table-column property="telPhone" label="负责人手机号"></el-table-column>
						<el-table-column property="hospitalName" label="巡检医院"></el-table-column>
						<el-table-column property="hospitalOfficeName" label="科室名称"></el-table-column>
						<!-- <el-table-column property="picture" label="图片"></el-table-column> -->
						<el-table-column property="state" label="状态">
							<template slot-scope="scope">
								<el-link v-if="Number(scope.row.state) === 0" type="info" :underline="false"
									style="color: #ff8431">
									未接单
								</el-link>
								<el-link v-else-if="Number(scope.row.state) === 1" type="info" :underline="false"
									style="color: #0ccbd2">
									进行中
								</el-link>
								<el-link v-else type="info" :underline="false" style="color: #15bc84">
									已完成
								</el-link>
							</template>
						</el-table-column>
						<el-table-column property="executionTime" label="执行时间">
							<template slot-scope="scope">
								{{ scope.row.executionTime | parseTime('{y}-{m}-{d}') }}
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="保养记录" name="3">
					<el-table ref="singleTable" :data="tableDataInspection" highlight-current-row style="width: 100%"
						:header-cell-style="{ background: '#F4F4F4' }">
						<el-table-column type="index" width="50"></el-table-column>
						<el-table-column property="listNum" label="单号"></el-table-column>
						<el-table-column property="devNumber" label="设备数量"></el-table-column>
						<el-table-column property="dutyPeopleName" label="计划负责人"></el-table-column>
						<el-table-column property="telPhone" label="负责人手机号"></el-table-column>
						<el-table-column property="hospitalName" label="保养医院"></el-table-column>
						<el-table-column property="hospitalOfficeName" label="科室名称"></el-table-column>
						<!-- <el-table-column property="picture" label="图片"></el-table-column> -->
						<el-table-column property="state" label="状态">
							<template slot-scope="scope">
								<el-link v-if="Number(scope.row.state) === 0" type="info" :underline="false"
									style="color: #ff8431">
									未接单
								</el-link>
								<el-link v-else-if="Number(scope.row.state) === 1" type="info" :underline="false"
									style="color: #0ccbd2">
									进行中
								</el-link>
								<el-link v-else type="info" :underline="false" style="color: #15bc84">
									已完成
								</el-link>
							</template>
						</el-table-column>
						<el-table-column property="executionTime" label="执行时间">
							<template slot-scope="scope">
								{{ scope.row.executionTime | parseTime('{y}-{m}-{d}') }}
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="计量记录" name="4">
					<el-table ref="singleTable" :data="tableDataMetering" highlight-current-row style="width: 100%"
						:header-cell-style="{ background: '#F4F4F4' }">
						<el-table-column type="index" width="50"></el-table-column>

						<el-table-column property="listNum" label="单号"></el-table-column>
						<el-table-column property="devNumber" label="设备数量"></el-table-column>
						<el-table-column property="dutyPeopleName" label="计划负责人"></el-table-column>
						<el-table-column property="telphone" label="负责人手机号"></el-table-column>
						<el-table-column property="hospitalName" label="计量医院"></el-table-column>
						<el-table-column property="hospitalOfficeName" label="科室名称"></el-table-column>
						<!-- <el-table-column property="markNumber" label="评价"></el-table-column> -->
						<el-table-column property="state" label="状态">
							<template slot-scope="scope">
								<el-link v-if="Number(scope.row.state) === 0" type="info" :underline="false"
									style="color: #ff8431">
									未接单
								</el-link>
								<el-link v-else-if="Number(scope.row.state) === 1" type="info" :underline="false"
									style="color: #0ccbd2">
									进行中
								</el-link>
								<el-link v-else type="info" :underline="false" style="color: #15bc84">
									已完成
								</el-link>
							</template>
						</el-table-column>
						<el-table-column property="executionTime" label="执行时间">
							<template slot-scope="scope">
								{{ scope.row.executionTime | parseTime('{y}-{m}-{d}') }}
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
			<el-pagination style="margin-top: 20px; margin-bottom: 20px" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="queryInfo.pageNum" :page-sizes="[10, 20, 30, 50]"
				:page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total"></el-pagination>
		</div>

		<!-- 批量打印设备二维码 -->
		<Dialog title="打印二维码" :visible.sync="isShowPrintDialog" width="320px">
			<PrintDev v-if="isShowPrintDialog" :isShowPrintDialog_p.sync="isShowPrintDialog" :selectData_p="selectData">
			</PrintDev>
		</Dialog>
		<Dialog title="设备历程" :visible.sync="isShowDeviceFlowDialog" width="40%">
			<div class="block">
				<el-timeline>
					<el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
						:type="activity.type" :color="activity.color" :size="activity.size"
						:timestamp="activity.timestamp">
						{{ activity.content }}
					</el-timeline-item>
				</el-timeline>
			</div>
		</Dialog>
		<Dialog title="相关证照" :visible.sync="isShowDeviceExtDialog" width="50%">
			<template v-if="deviceExtImgUrlsArr.length > 0">
				<el-image class="p5b1" style="width: 120px; height: 120px; margin-right: 14px" :src="item"
					:preview-src-list="deviceExtImgUrlsArr" :fit="'contain'"
					v-for="(item, index) in deviceExtImgUrlsArr" :key="index">
					<div slot="error" class="image-slot">暂无图片</div>
				</el-image>
			</template>
		</Dialog>
		<Dialog title="设备报废" :visible.sync="isShowtoggleStopUsingDialog" width="30%">
			<el-form ref="formtoggleStopUsing" :rules="rulestoggleStopUsing" :model="formtoggleStopUsing"
				label-width="80px">
				<el-form-item label="报废时间" prop="stopDate">
					<el-date-picker type="datetime" placeholder="选择日期" v-model="formtoggleStopUsing.stopDate"
						format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
						style="width: 100%;"></el-date-picker>
				</el-form-item>
				<el-form-item label="报废原因" prop="stopReason">
					<el-input type="textarea" v-model="formtoggleStopUsing.stopReason"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmittoggleStopUsing">确认报废</el-button>
					<el-button @click="toggleStopUsingHide">取消</el-button>
				</el-form-item>
			</el-form>
		</Dialog>
		<!-- // 关联审批 审批所有列表数据 -->
		<Dialog title="审批关联" :visible.sync="isShowApplyDialog" width="50%" custom-class="dialogApply dialogCom3">
			<div class="dialogApplySearch">
				<el-form class="form" ref="queryInfoApply" :model="queryInfoApply" label-width="60px"
					@submit.native.prevent @keyup.enter.native="getApplyList">
					<el-form-item label="标题">
						<el-input v-model="queryInfoApply.title" placeholder="标题" style="width:80px;" />
					</el-form-item>
					<el-form-item label="申请人">
						<el-input v-model="queryInfoApply.applicantName" placeholder="申请人" style="width:80px;" />
					</el-form-item>
					<el-form-item label="审批人">
						<el-input v-model="queryInfoApply.approverName" placeholder="审批人" style="width:80px;" />
					</el-form-item>
					<el-form-item label="日期">
						<el-date-picker v-model="dataArrApply" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
							style="width:210px;"></el-date-picker>
					</el-form-item>
					<el-form-item label="" label-width="0" style="margin-left:10px;">
						<el-button class="filter-item" size="mini" type="success" icon="el-icon-search"
							@click="getApplyList">
							搜索
						</el-button>
						<el-button class="filter-item" size="mini" icon="el-icon-refresh-left"
							@click="resetForm2('searchForm')">
							重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="primary" @click="addDevApply" :disabled="defaultSelectedIdsApply.length <= 0">
				关联审批到设备
			</el-button>
			<div style="width:100%; height:10px;"></div>
			<el-table ref="tableDataApply" :data="tableDataApply" highlight-current-row style="width: 100%"
				:header-cell-style="{ background: '#F4F4F4' }" :row-key="getRowKeys"
				@select="handleSelectionChangeApply" @select-all="handleSelectionChangeAllApply"
				class="tableDataApply tableDataNoChockbox">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column property="title" label="标题"></el-table-column>
				<el-table-column property="typeName" label="分类"></el-table-column>
				<el-table-column property="body" label="内容"></el-table-column>
				<el-table-column property="applicantName" label="申请人"></el-table-column>
				<el-table-column property="approverName" label="审批人"></el-table-column>
				<el-table-column property="applyDate" label="时间">
					<template slot-scope="scope">
						{{ scope.row.applyDate | parseTime('{y}-{m}-{d}') }}
					</template>
				</el-table-column>
			</el-table>

			<el-pagination style="margin: 20px;position:absolute; bottom:10px; right:0;"
				@size-change="handleApplySizeChange" @current-change="handleApplyCurrentChange"
				:current-page="queryInfoApply.pageNum" :page-sizes="[10, 20, 30, 50]"
				:page-size="queryInfoApply.pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="totalApply"></el-pagination>
		</Dialog>

		<!-- // 关联文档 文档所有列表数据 -->
		<Dialog title="文档关联" :visible.sync="isShowDocDialog" width="50%" custom-class="dialogDoc dialogCom3">
			<div class="dialogDocSearch">
				<el-form class="form" ref="queryInfoDoc" :model="queryInfoDoc" label-width="60px" @submit.native.prevent
					@keyup.enter.native="getDocList">
					<el-form-item label="类型">
						<el-select v-model="queryInfoDoc.typeid" placeholder="请选择" clearable filterable>
							<el-option v-for="item in typeData" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="标题">
						<el-input v-model="queryInfoDoc.title" placeholder="标题" />
					</el-form-item>

					<el-form-item label="" label-width="0" style="margin-left:10px;">
						<el-button class="filter-item" size="mini" type="success" icon="el-icon-search"
							@click="getDocList">
							搜索
						</el-button>
						<el-button class="filter-item" size="mini" icon="el-icon-refresh-left"
							@click="resetFormDoc('searchForm')">
							重置
						</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="primary" @click="addDevDoc" :disabled="defaultSelectedIdsDoc.length <= 0">
				关联文档到设备
			</el-button>
			<div style="width:100%; height:10px;"></div>
			<el-table ref="tableDataDoc" :data="tableDataDoc" highlight-current-row style="width: 100%"
				:header-cell-style="{ background: '#F4F4F4' }" :row-key="getRowKeys" @select="handleSelectionChangeDoc"
				@select-all="handleSelectionChangeAllDoc" class="tableDataDoc tableDataNoChockbox">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column property="title" label="标题"></el-table-column>
				<el-table-column property="creatorName" label="上传人"></el-table-column>
				<el-table-column property="createTime" label="上传时间">
					<template slot-scope="scope">
						{{ scope.row.createTime | parseTime('{y}-{m}-{d}') }}
					</template>
				</el-table-column>
			</el-table>

			<el-pagination style="margin: 20px;position:absolute; bottom:10px; right:0;"
				@size-change="handleDocSizeChange" @current-change="handleDocCurrentChange"
				:current-page="queryInfoDoc.pageNum" :page-sizes="[10, 20, 30, 50]" :page-size="queryInfoDoc.pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="totalDoc"></el-pagination>
		</Dialog>

		<!-- // 关联操作 操作增删改查 -->
		<Dialog title="文档操作" :visible.sync="isShowOprateDialog" width="30%" custom-class="dialogOprate ">
			<div>
				<el-form ref="formInfoOprate" :model="formInfoOprate" :rules="rulesOprate" label-width="100px"
					size="small">
					<el-form-item label="操作内容" prop="body">
						<el-input type="textarea" :rows="2" v-model="formInfoOprate.body" placeholder="请输入操作内容" />
					</el-form-item>
					<el-form-item label="时间" prop="opdate">
						<el-date-picker v-model="formInfoOprate.opdate" type="datetime" placeholder="请选择日期时间"
							value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
					</el-form-item>
					<el-form-item label="操作人" prop="opname">
						<el-input v-model="formInfoOprate.opname" placeholder="请输入操作人" />
					</el-form-item>

					<el-form-item label="操作结果" prop="opresult">
						<el-input v-model="formInfoOprate.opresult" placeholder="请输入操作结果" />
					</el-form-item>
					<el-form-item>
						<div style="text-align: right">
							<span class="dialog-footer">
								<el-button @click="formCancelOprate">取 消</el-button>
								<el-button type="primary" @click="formSubmitOprate">
									确 定
								</el-button>
							</span>
						</div>
					</el-form-item>
				</el-form>
			</div>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
	devBaseListService,
	devBaseDeleteService,
	devBaseDetailService,
	queryOneDevService,
	getDevRepairAllService,
	getDevMaintainAllService,
	getDevInspectionAllService,
	getDevMeteringAllService,
	getDevFlowService,
	devBasetoggleStopUsingService
} from '@s/hospital/devBase/DevBaseService';
// eslint-disable-next-line import/no-cycle
import {
	listService as listServiceApply,
	addapply2devService,
	delapplydevService
} from '@s/doc/ApplyService';

// eslint-disable-next-line import/no-cycle
import {
	selectfromdocdevService,
	adddoc2devService,
	deletefromdevdevdocService,
	docListService
} from '@s/doc/DocService';
// eslint-disable-next-line import/no-cycle
import { typeListAllService } from '@s/doc/TypeService';
// import {
// selectfromdocdevService,
// adddoc2devService,
// deletefromdevdevdocService,
// docListService
// } from '@s/doc/DocService';
// eslint-disable-next-line import/no-cycle
import {
	listService as listServiceOprate,
	addService as addServiceOprate,
	editService as editServiceOprate,
	deleteService as deleteServiceOprate,
	detailService as detailServiceOprate
} from '@s/doc/OprateService';

import Dialog from '@c/ui/Dialog';
import PrintDev from '@f/hospital/devBase/PrintDev';
import QRCode from 'qrcodejs2';

import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	mixins: [ListMixin],
	components: { Dialog, PrintDev },
	// name 机构名称
	// levelId 医院等级
	// website 官方网址
	// createUserId 添加人
	// phone 联系电话
	// province 省
	// city 市
	// county 县
	// address 地址详情
	data() {
		return {
			activeBaseInfoName: 'first',
			formtoggleStopUsing: {
				id: null,
				// stoped: null, // true为已报废 false或null为未报废
				stopDate: null, // 报废时间
				stopReason: '' // 报废原因
			},
			rulestoggleStopUsing: {
				stopDate: [{ required: true, message: '请输选择报废时间', trigger: 'checked' }],
				stopReason: [{ required: true, message: '请输入报废原因', trigger: 'blur' }]
			},
			activities: [
				/* {
					content: '支持使用图标',
					timestamp: '2018-04-12 20:46',
					size: 'large',
					type: 'primary',
					icon: 'el-icon-more'
				},
				{
					content: '支持自定义颜色',
					timestamp: '2018-04-03 20:46',
					color: '#0bbd87'
				},
				{
					content: '支持自定义尺寸',
					timestamp: '2018-04-03 20:46',
					size: 'large'
				},
				{
					content: '默认样式的节点',
					timestamp: '2018-04-03 20:46'
				} */
			],
			// 表格
			tableColumn: [],
			delTips: '',
			currentUserOrganization: null,
			devBaseForm: {
				org: {}
			},

			currentPage: 4,
			activeName: '1',
			// 维修
			tableDataRepair: [],
			// 保养
			tableDataMaintain: [],
			// 巡检
			tableDataInspection: [],
			// 计量
			tableDataMetering: [],
			queryInfo: {
				pageNum: 1,
				pageSize: 10
			},
			printObj: {
				id: 'printMe', // 要打印的dom的id值
				popTitle: '设备二维码',
				// extraCss: 'https://www.google.com', // 附加连接
				extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>' // 附加头
			},
			isShowPrintDialog: false,
			isShowDeviceFlowDialog: false,
			isShowDeviceExtDialog: false,
			isShowtoggleStopUsingDialog: false,
			deviceExtImgUrlsArr: [],
			// 设备相关联的审批
			tableDataDevApply: [],
			queryInfoDevApply: {
				pageNum: 1,
				pageSize: 10
			},
			totalDevApply: 0,
			dataArrDevApply: [],

			// 设备相关联的审批 所有审批列表
			isShowApplyDialog: false,
			tableDataApply: [],

			defaultSelectedIdsApply: [], // 默认选中数据ID
			multipleSelectionApply: [], // 选中的所有行数据
			queryInfoApply: {
				pageNum: 1,
				pageSize: 10
			},
			totalApply: 0,
			dataArrApply: [],

			// 设备 相关文档
			tableDataDevDoc: [],
			queryInfoDevDoc: {
				pageNum: 1,
				pageSize: 10
			},
			totalDevDoc: 0,
			dataArrDevDoc: [],
			token: localStorage.getItem('token'),
			// 设备相关联的文档 所有文档列表
			typeData: [],
			isShowDocDialog: false,
			tableDataDoc: [],

			defaultSelectedIdsDoc: [], // 默认选中数据ID
			multipleSelectionDoc: [], // 选中的所有行数据
			queryInfoDoc: {
				pageNum: 1,
				pageSize: 10
			},
			totalDoc: 0,
			dataArrDoc: [],
			// 设备 相关操作
			tableDataDevOprate: [],
			queryInfoDevOprate: {
				pageNum: 1,
				pageSize: 10
			},
			totalDevOprate: 0,
			dataArrDevOprate: [],
			// 设备 相关操作 增改
			isShowOprateDialog: false,
			editIdOprateId: null,
			formInfoOprate: {},
			rulesOprate: {
				body: [
					{
						required: true,
						message: '请输入操作内容',
						trigger: 'blur'
					}
				],
				opdate: [
					{
						required: true,
						message: '请选择日期时间',
						trigger: 'blur'
					}
				],
				opname: [
					{
						required: true,
						message: '请输入操作人',
						trigger: 'blur'
					}
				],
				opresult: [
					{
						required: true,
						message: '请输入操作结果',
						trigger: 'blur'
					}
				]
			}
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		// dataArrApply: {
		// 	handler(newValue) {
		// 		if (newValue) {
		// 			[
		// 				this.queryInfoApply.applyDatebegin,
		// 				this.queryInfoApply.applyDateend
		// 			] = newValue;
		// 			// this.searchForm.applyDateend = newValue[1];
		// 		}
		// 	}
		// },
		// dataArrDevApply: {
		// 	handler(newValue) {
		// 		if (newValue) {
		// 			[
		// 				this.queryInfoDevApply.applyDatebegin,
		// 				this.queryInfoDevApply.applyDateend
		// 			] = newValue;
		// 			// this.searchForm.applyDateend = newValue[1];
		// 		}
		// 	}
		// },
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.devBaseList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.devBaseList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.devBaseList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.devBaseList();
				} else {
					this.pageIndex = 1;
				}
			}
		},
		activeName() {
			this.queryInfo.pageNum = 1;
			this.queryInfo.pageSize = 10;
			this.getDetailList();
		}
	},
	mounted() {
		// this.devBaseList();
		this.currentUserOrganization = hgetStorage('organization');
		console.log(this.$route.query.devId);
		this.queryOneDevService();

		this.getDetailList();

		// 设备审批 列表
		this.getDevApplyList();
	},
	methods: {
		toggleStopUsingShow() {
			this.isShowtoggleStopUsingDialog = true;
		},
		toggleStopUsingHide() {
			this.isShowtoggleStopUsingDialog = false;
			this.formtoggleStopUsing = {
				id: null,
				// stoped: null, // true为已报废 false或null为未报废
				stopDate: null, // 报废时间
				stopReason: '' // 报废原因
			};
		},
		onSubmittoggleStopUsing() {
			const dataJson = {
				...this.formtoggleStopUsing
			};
			dataJson.stoped = this.devBaseForm.stoped;

			this.$refs.formtoggleStopUsing.validate(async valid => {
				if (valid) {
					const res = await devBasetoggleStopUsingService(dataJson);
					console.log(res);
					this.toggleStopUsingHide();
					this.queryOneDevService();
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		onCanceltoggleStopUsing() {
			this.$confirm('确认取消报废？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const dataJson = {
						...this.formtoggleStopUsing
					};
					dataJson.stoped = this.devBaseForm.stoped;
					const res = await devBasetoggleStopUsingService(dataJson);
					console.log(res);
					this.toggleStopUsingHide();
					this.queryOneDevService();
					this.$message({
						type: 'success',
						message: '取消报废成功!'
					});
				})
				.catch(() => {
					// this.$message({
					// 	type: 'info',
					// 	message: '已取消删除'
					// });
				});
		},
		deviceExtHandler() {
			this.isShowDeviceExtDialog = true;
		},
		deviceFlowHandler() {
			this.isShowDeviceFlowDialog = true;
			this.getDevFlowService();
		},
		async getDevFlowService() {
			const dataJson = {
				devid: this.$route.query.devId
			};
			const res = await getDevFlowService(dataJson);
			console.log(res);
			this.activities = [];
			res.forEach(item => {
				if (item.type === 0) {
					this.activities.push({
						content: item.typeName,
						timestamp: item.dateTime,
						color: 'rgb(14, 61, 216)'
					});
				} else if (item.type === 1) {
					this.activities.push({
						content: item.typeName,
						timestamp: item.dateTime,
						color: 'rgb(221, 140, 18)'
					});
				} else {
					this.activities.push({
						content: item.typeName,
						timestamp: item.dateTime
					});
				}
			});
		},
		// 相关审批开始 S
		// 设备审批 列表
		initSearchForm0() {
			this.dataArrDevApply = [];
			this.queryInfoDevApply = {
				pageNum: 1,
				pageSize: 10
			};
		},
		resetForm1(formName) {
			this.initSearchForm0();
			this.getDevApplyList();
		},
		async getDevApplyList() {
			if (this.dataArrDevApply) {
				[
					this.queryInfoDevApply.applyDatebegin,
					this.queryInfoDevApply.applyDateend
				] = this.dataArrDevApply;
			} else {
				[this.queryInfoDevApply.applyDatebegin, this.queryInfoDevApply.applyDateend] = [
					null,
					null
				];
			}
			const dataJson = {
				devid: this.$route.query.devId,
				...this.queryInfoDevApply
			};
			const res = await listServiceApply(dataJson);
			this.totalDevApply = res.total;
			this.tableDataDevApply = [...res.records];
			this.defaultSelectedIdsApply = this.tableDataDevApply?.map(item => item.id);
		},
		handleDevApplySizeChange(val) {
			console.log(`设备审批 列表每页 ${val} 条`);
			this.queryInfoDevApply.pageSize = val;
			this.getDevApplyList();
		},
		handleDevApplyCurrentChange(val) {
			console.log(`设备审批 列表当前页: ${val}`);
			this.queryInfoDevApply.pageNum = val;
			this.getDevApplyList();
		},
		showApplyDialog() {
			this.isShowApplyDialog = true;
			this.getApplyList();
		},
		// 审批列表 全部
		initSearchForm1() {
			this.dataArrApply = [];
			this.queryInfoApply = {
				pageNum: 1,
				pageSize: 10
			};
		},
		resetForm2(formName) {
			this.initSearchForm1();
			this.getApplyList();
		},
		async getApplyList() {
			if (this.dataArrApply) {
				[
					this.queryInfoApply.applyDatebegin,
					this.queryInfoApply.applyDateend
				] = this.dataArrApply;
			} else {
				[this.queryInfoApply.applyDatebegin, this.queryInfoApply.applyDateend] = [
					null,
					null
				];
			}
			const dataJson = {
				...this.queryInfoApply
			};
			const res = await listServiceApply(dataJson);
			this.totalApply = res.total;
			this.tableDataApply = res.records;

			// 判断在defaultSelectedIds中含有的，通过方法去选中，保留defaultSelectedIds是因为defaultSelectedIds中的值无法对全部页选中
			this.$nextTick(() => {
				if (this.tableDataApply && this.defaultSelectedIdsApply) {
					this.tableDataApply.forEach(row => {
						if (this.defaultSelectedIdsApply.includes(row.id)) {
							this.multipleSelectionApply.push(row);
							this.$refs.tableDataApply.toggleRowSelection(row, true);
						}
					});
				}
			});
		},
		// 获取行的key，用于表格保留分页选中
		getRowKeys(row) {
			return row.id;
		},
		handleSelectionChangeApply(selection, row) {
			const index1 = selection?.findIndex(item => item.id == row.id);

			const index = this.defaultSelectedIdsApply.indexOf(row.id);

			// 选中了 默认列表没有 则添加
			if (index1 != -1 && index == -1) {
				this.defaultSelectedIdsApply.push(row.id);
			}
			// 没选中 默认列表有 则删除
			if (index1 == -1 && index != -1) {
				this.defaultSelectedIdsApply.splice(index, 1);
				this.delDevApply([row.id]);
			}

			// if (this.multipleSelectionApply.length > selection.length && index !== -1) {
			// 	this.defaultSelectedIdsApply.splice(index, 1);
			// }
			this.multipleSelectionApply = selection;
		},
		handleSelectionChangeAllApply(selection) {
			console.log(selection);
		},
		async addDevApply() {
			const dataJsonArr = this.defaultSelectedIdsApply?.map(item => {
				return {
					id: item,
					devid: this.$route.query.devId
				};
			});

			console.log('this.multipleSelectionApply');
			console.log(this.defaultSelectedIdsApply);
			console.log(dataJsonArr);

			dataJsonArr?.forEach(async (item, index, arr) => {
				const res = await addapply2devService({ ...item });
				if (index == arr.length - 1) {
					this.isShowApplyDialog = false;
					this.getDevApplyList();
				}
			});
		},
		async delDevApply(idArr) {
			const dataJsonArr = idArr?.map(item => {
				return {
					id: item,
					devid: this.$route.query.devId
				};
			});
			dataJsonArr?.forEach(async (item, index, arr) => {
				const res = await delapplydevService({ ...item });
				if (index == arr.length - 1) {
					this.getDevApplyList();
				}
			});
		},
		async delDevApplyDev(idArr) {
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					const dataJsonArr = idArr?.map(item => {
						return {
							id: item,
							devid: this.$route.query.devId
						};
					});
					dataJsonArr?.forEach(async (item, index, arr) => {
						const res = await delapplydevService({ ...item });
						if (index == arr.length - 1) {
							this.getDevApplyList();
						}
					});
				})
				.catch(error => {
					console.log(error);
				});
		},

		downloadFile(row) {
			// http://img.ygongyun.com/apply/d8122932-884a-43cc-be6f-c50f24d39405.docx
			window.location.href = `${this.$envConfig.imgHttpAdd}apply/${row.filepath}`;
			// window.location.href = `${this.$envConfig.OfficialWebsite}web/base/api/docs/download?id=${row.id}&token=${this.token}`;
		},
		handleApplySizeChange(val) {
			console.log(`设备审批 列表每页 ${val} 条`);
			this.queryInfoApply.pageSize = val;
			this.getApplyList();
			console.log(this.defaultSelectedIdsApply);
		},
		handleApplyCurrentChange(val) {
			console.log(`设备审批 列表当前页: ${val}`);
			this.queryInfoApply.pageNum = val;
			this.getApplyList();
		},
		// 相关审批结束 E

		// 相关文档开始 S
		initSearchFormDevDoc() {
			this.dataArrDevDoc = [];
			this.queryInfoDevDoc = {
				pageNum: 1,
				pageSize: 10
			};
		},
		resetFormDevDoc(formName) {
			this.initSearchFormDevDoc();
			this.getDevDocList();
		},
		async getDevDocList() {
			if (this.dataArrDevDoc) {
				[
					this.queryInfoDevDoc.applyDatebegin,
					this.queryInfoDevDoc.applyDateend
				] = this.dataArrDevDoc;
			} else {
				[this.queryInfoDevDoc.applyDatebegin, this.queryInfoDevDoc.applyDateend] = [
					null,
					null
				];
			}
			const dataJson = {
				devid: this.$route.query.devId,
				...this.queryInfoDevDoc
			};
			const res1 = await selectfromdocdevService(dataJson);
			const ids = [...new Set(res1)];

			console.log({ ...dataJson, ids });
			if (ids.length > 0) {
				const res = await docListService({ ...dataJson, ids });
				this.totalDevDoc = res.total;
				this.tableDataDevDoc = [...res.records];
				console.log('res1 文档 id');
				console.log(res.records);
			} else {
				this.tableDataDevDoc = [];
			}
			// this.defaultSelectedIdsDoc = this.tableDataDevDoc?.map(item => item.id);
			this.defaultSelectedIdsDoc = ids;
		},
		handleDevDocSizeChange(val) {
			console.log(`设备文档 列表每页 ${val} 条`);
			this.queryInfoDevDoc.pageSize = val;
			this.getDevDocList();
		},
		handleDevDocCurrentChange(val) {
			console.log(`设备文档 列表当前页: ${val}`);
			this.queryInfoDevDoc.pageNum = val;
			this.getDevDocList();
		},
		showDocDialog() {
			this.isShowDocDialog = true;
			this.typeList();
			this.getDocList();
		},
		// 文档列表 全部
		initSearchFormDoc() {
			this.dataArrDoc = [];
			this.queryInfoDoc = {
				pageNum: 1,
				pageSize: 10
			};
		},
		resetFormDoc(formName) {
			this.initSearchFormDoc();
			this.getDocList();
		},

		async typeList() {
			const dataJson = {};
			const res = await typeListAllService(dataJson);
			this.typeData = res;
		},
		async getDocList() {
			if (this.dataArrDoc) {
				[
					this.queryInfoDoc.applyDatebegin,
					this.queryInfoDoc.applyDateend
				] = this.dataArrDoc;
			} else {
				[this.queryInfoDoc.applyDatebegin, this.queryInfoDoc.applyDateend] = [null, null];
			}

			const dataJson = { ...this.queryInfoDoc };
			const res = await docListService(dataJson);
			this.totalDoc = res.total;
			this.tableDataDoc = res.records;

			// 判断在defaultSelectedIds中含有的，通过方法去选中，保留defaultSelectedIds是因为defaultSelectedIds中的值无法对全部页选中
			this.$nextTick(() => {
				if (this.tableDataDoc && this.defaultSelectedIdsDoc) {
					this.tableDataDoc.forEach(row => {
						if (this.defaultSelectedIdsDoc.includes(row.id)) {
							this.multipleSelectionDoc.push(row);
							this.$refs.tableDataDoc.toggleRowSelection(row, true);
						}
					});
				}
			});
		},
		// 获取行的key，用于表格保留分页选中
		getRowKeysDoc(row) {
			return row.id;
		},
		handleSelectionChangeDoc(selection, row) {
			const index1 = selection?.findIndex(item => item.id == row.id);

			const index = this.defaultSelectedIdsDoc.indexOf(row.id);

			// 选中了 默认列表没有 则添加
			if (index1 != -1 && index == -1) {
				this.defaultSelectedIdsDoc.push(row.id);
			}
			// 没选中 默认列表有 则删除
			if (index1 == -1 && index != -1) {
				this.defaultSelectedIdsDoc.splice(index, 1);
				this.delDevDoc([row.id]);
			}

			// if (this.multipleSelectionDoc.length > selection.length && index !== -1) {
			// 	this.defaultSelectedIdsDoc.splice(index, 1);
			// }
			this.multipleSelectionDoc = selection;
		},
		handleSelectionChangeAllDoc(selection) {
			console.log(selection);
		},
		async addDevDoc() {
			const dataJsonArr = this.defaultSelectedIdsDoc?.map(item => {
				return {
					id: item,
					devid: this.$route.query.devId
				};
			});

			console.log('this.multipleSelectionDoc');
			console.log(this.defaultSelectedIdsDoc);
			console.log(dataJsonArr);

			dataJsonArr?.forEach(async (item, index, arr) => {
				const res = await adddoc2devService({ ...item });
				if (index == arr.length - 1) {
					this.isShowDocDialog = false;
					this.getDevDocList();
				}
			});
		},
		async delDevDoc(idArr) {
			const dataJsonArr = idArr?.map(item => {
				return {
					id: item,
					devid: this.$route.query.devId
				};
			});
			dataJsonArr?.forEach(async (item, index, arr) => {
				const res = await deletefromdevdevdocService({ ...item });
				if (index == arr.length - 1) {
					this.getDevDocList();
				}
			});
		},
		async delDevDocDev(idArr) {
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					const dataJsonArr = idArr?.map(item => {
						return {
							id: item,
							devid: this.$route.query.devId
						};
					});
					dataJsonArr?.forEach(async (item, index, arr) => {
						const res = await deletefromdevdevdocService({ ...item });
						if (index == arr.length - 1) {
							this.getDevDocList();
						}
					});
				})
				.catch(error => {
					console.log(error);
				});
		},

		// downloadFileDevDoc(row) {
		// 	// http://img.ygongyun.com/apply/d8122932-884a-43cc-be6f-c50f24d39405.docx
		// 	window.location.href = `${this.$envConfig.imgHttpAdd}apply/${row.filepath}`;
		// 	// window.location.href = `${this.$envConfig.OfficialWebsite}web/base/api/docs/download?id=${row.id}&token=${this.token}`;
		// },

		downloadFileDevDoc(row) {
			console.log(this.token);
			console.log(
				`${this.$envConfig.OfficialWebsite}web/base/api/docs/download?id=${row.id}&token=${this.token}`
			);
			window.location.href = `${this.$envConfig.OfficialWebsite}web/base/api/docs/download?id=${row.id}&token=${this.token}`;
		},
		handleDocSizeChange(val) {
			console.log(`设备文档 列表每页 ${val} 条`);
			this.queryInfoDoc.pageSize = val;
			this.getDocList();
			console.log(this.defaultSelectedIdsDoc);
		},
		handleDocCurrentChange(val) {
			console.log(`设备文档 列表当前页: ${val}`);
			this.queryInfoDoc.pageNum = val;
			this.getDocList();
		},
		// 相关文档结束 E

		// region 相关操作开始 S
		initSearchFormDevOprate() {
			this.dataArrDevOprate = [];
			this.queryInfoDevOprate = {
				pageNum: 1,
				pageSize: 10
			};
		},
		resetFormDevOprate(formName) {
			this.initSearchFormDevOprate();
			this.getDevOprateList();
		},
		async getDevOprateList() {
			if (this.dataArrDevOprate) {
				[
					this.queryInfoDevOprate.opdatebegin,
					this.queryInfoDevOprate.opdateend
				] = this.dataArrDevOprate;
			} else {
				[this.queryInfoDevOprate.opdatebegin, this.queryInfoDevOprate.opdateend] = [
					null,
					null
				];
			}
			const dataJson = {
				devid: this.$route.query.devId,
				...this.queryInfoDevOprate
			};
			const res = await listServiceOprate({ ...dataJson });
			this.totalDevOprate = res.total;
			this.tableDataDevOprate = [...res.records];
		},
		handleDevOprateSizeChange(val) {
			console.log(`设备操作 列表每页 ${val} 条`);
			this.queryInfoDevOprate.pageSize = val;
			this.getDevOprateList();
		},
		handleDevOprateCurrentChange(val) {
			console.log(`设备操作 列表当前页: ${val}`);
			this.queryInfoDevOprate.pageNum = val;
			this.getDevOprateList();
		},
		async showOprateDialog() {
			if (this.editIdOprateId) {
				await this.getDetailOprate();
			}
			this.isShowOprateDialog = true;
		},

		formCancelOprate() {
			this.isShowOprateDialog = false;
			this.formInfoOprate = {};
			this.editIdOprateId = null;
		},
		formSubmitOprate() {
			this.formInfoOprate.devid = this.$route.query.devId;
			this.$refs.formInfoOprate.validate(async valid => {
				if (valid) {
					if (this.editIdOprateId === null) {
						await addServiceOprate(this.formInfoOprate);
					} else {
						const dataJson = {
							id: this.editIdOprateId,
							...this.formInfoOprate
						};
						await editServiceOprate(dataJson);
					}
					this.getDevOprateList();
					this.formCancelOprate();
				} else {
					console.log('验证失败');
				}
			});
		},
		edidSingleHandlerOprate(row) {
			this.editIdOprateId = row.id;
			this.showOprateDialog();
		},
		async deleteSingleHandlerOprate(ids) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const dataJson = {
						ids: ids
					};
					await deleteServiceOprate(dataJson);
					if (this.totalDevOprate % this.queryInfoDevOprate.pageSize == 1) {
						this.queryInfoDevOprate.pageNum = 1;
					}
					this.getDevOprateList();
				})
				.catch(error => {
					console.log(error);
				});
		},

		async getDetailOprate() {
			const dataJson = {
				id: this.editIdOprateId
			};
			const res = await detailServiceOprate(dataJson);
			this.formInfoOprate = { ...res };
		},
		// endregion 相关操作结束 E

		// 报修记录 表格一
		async getDevRepairAllService() {
			const res = await getDevRepairAllService({
				id: this.$route.query.devId,
				...this.queryInfo
			});
			this.total = res.total;
			this.tableDataRepair = [...res.records];
		},
		// 保养记录 表格二
		async getDevMaintainAllService() {
			const res = await getDevMaintainAllService({
				devId: this.$route.query.devId,
				...this.queryInfo
			});
			this.total = res.total;
			this.tableDataMaintain = [...res.records];
		},
		// 巡检记录 表格三
		async getDevInspectionAllService() {
			const res = await getDevInspectionAllService({
				devId: this.$route.query.devId,
				...this.queryInfo
			});
			this.total = res.total;
			this.tableDataInspection = [...res.records];
		},
		// 计量记录 表格四
		async getDevMeteringAllService() {
			const res = await getDevMeteringAllService({
				devId: this.$route.query.devId,
				...this.queryInfo
			});
			// res.records.forEach(item => {
			// 	item.executionTime = this.parseTime(item.executionTime, '{y}-{m}-{d}');
			// });
			this.total = res.total;
			this.tableDataMetering = [...res.records];
		},

		getDetailList() {
			console.log('this.activeName');
			console.log(this.activeName);

			if (Number(this.activeName) === 1) {
				this.getDevRepairAllService();
			} else if (Number(this.activeName) === 2) {
				this.getDevMaintainAllService();
			} else if (Number(this.activeName) === 3) {
				this.getDevInspectionAllService();
			} else if (Number(this.activeName) === 4) {
				this.getDevMeteringAllService();
			}
		},

		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
			this.queryInfo.pageSize = val;
			this.getDetailList();
		},
		handleCurrentChange(val) {
			console.log(`当前页: ${val}`);
			this.queryInfo.pageNum = val;
			this.getDetailList();
		},

		setQRcode() {
			// const qrcode = new QRCode(this.$refs.qrcode, {
			const qrcode = new QRCode(this.$refs.qrcode, {
				// 这里‘qrcode’是dom对象，也可以是this.$refs.qrcode
				// text: `yg:deviceId=${this.devBaseForm.devInfoId}&hospitalId=${this.devBaseForm.affiliatedHospitalId}`,
				// https://ygongyun.com/bx?deviceId=1&hospitalId=1095
				text: `${this.$envConfig.OfficialWebsite}bx?deviceId=${this.devBaseForm.devInfoId}&hospitalId=${this.devBaseForm.affiliatedHospitalId}`,
				width: 90, // 图像宽度
				height: 90, // 图像高度
				colorDark: '#000000', // 前景色
				colorLight: '#ffffff', // 背景色
				typeNumber: 4,
				// 容错级别 容错级别有：
				// （1）QRCode.CorrectLevel.L
				// （2）QRCode.CorrectLevel.M
				// （3）QRCode.CorrectLevel.Q
				// （4）QRCode.CorrectLevel.H
				correctLevel: QRCode.CorrectLevel.L
			});
			console.log(qrcode, '-----------------');
		},
		// 获取设备详情
		async queryOneDevService() {
			const json = {
				devId: this.$route.query.devId
			};
			const res = await queryOneDevService(json);
			// eslint-disable-next-line no-self-assign

			if (!res.org) {
				res.org = {};
			}
			res.org.logoStr = '';
			if (res.devInfoImgUrl) {
				if (res.devInfoImgUrl.indexOf(',') !== -1) {
					res.devInfoImgUrlStr =
						this.$envConfig.imgHttpAdd +
						this.$envConfig.devbase +
						res.devInfoImgUrl.split(',')[0];
				} else {
					res.devInfoImgUrlStr =
						this.$envConfig.imgHttpAdd + this.$envConfig.devbase + res.devInfoImgUrl;
				}
			}
			if (res.devExtImgUrls) {
				const tempArr = res.devExtImgUrls.split(',');
				tempArr.forEach(item => {
					this.deviceExtImgUrlsArr.push(
						this.$envConfig.imgHttpAdd + this.$envConfig.devbase + item
					);
				});
			}
			if (res.org && res.org.logo) {
				res.org.logoStr = `${this.$envConfig.imgHttpAdd}orglogo/${res.org.logo}`;
			}
			this.devBaseForm = res;

			this.formtoggleStopUsing.stoped = this.devBaseForm.stoped || false;
			this.formtoggleStopUsing.id = this.devBaseForm.devInfoId;
			this.selectData = [res];
			this.setQRcode();
		},
		handleClickBaseInfoTab(tab, event) {
			console.log(tab, event);
			if (this.activeBaseInfoName == 'five') {
				this.getDevApplyList();
			}
			if (this.activeBaseInfoName == 'six') {
				this.getDevDocList();
			}
			if (this.activeBaseInfoName == 'seven') {
				this.getDevOprateList();
			}
		},
		// 切换信息
		handleClickTab(tab, event) {
			console.log(tab, event);
		},
		// 打印设备的二维码
		printDevBase() {
			window.print();
			/* this.$message.success('点击打印成功~~~'); */
		},
		async devBaseList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await devBaseListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				devId: editId
			};
			const res = await devBaseDetailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await devBaseDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async viewSingleHandler(row) {
			console.log(row);
		},
		async editSingleHandler(row) {
			const dataJson = {
				devId: row.devInfoId
			};
			const res = await devBaseDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			// this.deleteSingleHandlerMixin(row);
			this.$confirm(this.delTips || '确认此操作吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(async () => {
					const ids = [];
					ids.push(row.devInfoId);
					const dataJson = {
						ids: ids
					};
					await devBaseDeleteService(dataJson);
					this.isRefreshList = true;
				})
				.catch(error => {
					console.log(error);
				});
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.devBaseList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.devInfoId;
		},
		showPrintDialog() {
			console.log('this.devBaseForm');
			console.log(this.selectData);
			this.isShowPrintDialog = true;
		}
	}
};
</script>
<style lang="less" scoped>
h3 {
	margin-bottom: 30px;
}

.wrapTop {
	display: flex;
}

.wrapTop .baseInfo {
	display: flex;
}

.tabInfo {
	padding-left: 30px;
	display: flex;
	flex: 1;
}

.wrapTop .baseInfo ul {
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	// padding: 0 0 0 30px;
}

.wrapTop .baseInfo ul li {
	display: flex;
	justify-content: space-between;
	width: 45%;
	margin-right: 5%;
	border-bottom: 1px dashed #cccccc;
	// height: 30px;
	line-height: 30px;
	margin-top: 20px;
}

.wrapTop .baseInfo ul li b {
	width: 1.4rem;
	color: #676968;
	font-weight: 700;
}

.wrapTop .baseInfo ul li span {
	color: #afaeb3;
	flex: 1;
	text-align: right;
}

.comTitle {
	color: #373737;
	font-weight: 700;
}

// 二维码
.QRCode {
	position: relative;
	// margin-left: 20px;
	// margin-right: 20px;
}

.QRCode .pringQR {
	// position: absolute;
	// top: 150px;
	// right: 24px;
	cursor: pointer;
	text-align: center;
}

.QRCode .pringQR i {
	// font-weight: bold;
	// font-size: 34px;
}

.companyInfo {
	text-align: center;
}

/* 维修单状态 */
/* 待接单 */
.equip_state {
	//   width: 101rpx;
	//   height: 42rpx;
	//   border: 1px solid #FF8431;
	color: #ff8431;
	//   text-align: center;
	//   line-height: 40rpx;
	//   border-radius: 6rpx;
}

/* 已响应 */
.equip_state1 {
	color: #0d69f5;
}

/* 已到场 */
.equip_state2 {
	color: #760cd2;
}

/* 维修中 */
.equip_state3 {
	color: #0ccbd2;
}

/* 待评价 */
.equip_state4 {
	color: #c1e986;
}

/* 已完成 */
.equip_state5 {
	color: #15bc84;
}

.yellow {
	color: #ffd21e;
}

.f14 {
	font-size: 0.2rem;
}

/deep/.el-image-viewer__close {
	top: 0.8rem !important;
	color: #ffffff;
}

// 打印二维码
#printMe {
	// width: 200px;
	// height: 250px;
	position: relative;
}

.codeBac {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: #fff;
}

.colorafaeb3 {
	color: #afaeb3;
}

.f16 {
	font-size: 0.16rem;
}

.p5b1 {
	padding: 0.05rem;
	border: 1px solid #cccccc;
}

/deep/.QRCode .el-button--warning.is-plain:focus,
/deep/.QRCode .el-button--warning.is-plain:hover {
	background: #fdf6ec;
	border-color: #f5dab1;
	color: #e6a23c;
	cursor: default;
}

/deep/.QRCode .el-button--success.is-plain:focus,
/deep/.QRCode .el-button--success.is-plain:hover {
	color: #67c23a;
	background: #f0f9eb;
	border-color: #c2e7b0;
	cursor: default;
}

/deep/ .box-card-QR .el-card__header {
	padding: 0.13rem 0.2rem 0.12rem;
}

/deep/ .box-card-QR .comTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/deep/ .image-slot {
	line-height: 1.18rem;
	text-align: center;
	font-weight: normal;
}

// 深度选择器 去掉全选按钮
/deep/ .tableDataNoChockbox .el-table__header-wrapper .el-checkbox__inner {
	display: none;
}

/deep/ .dialogCom3 {
	min-height: 800px !important;
	height: 800px !important;
	position: relative !important;
}

/deep/ .dialogCom3 .el-table__body-wrapper {
	max-height: 500px !important;
	overflow-y: auto !important;
}
</style>
